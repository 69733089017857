<template>
  <mobile-screen
    :header="true"
    screen-class="resources-availability gray-bg icon-app1"
  >
    <template v-slot:header>
      <top-header-menu-wrapper
        menu-class="resource-header icon-hea1"
        :helpOnline="
          Boolean(
            helpOnline[helpSlug] &&
              helpOnline[helpSlug].name &&
              helpOnline[helpSlug].content
          )
        "
        :helpOnlineRoute="{
          name: 'r_resources-help-online-availability'
        }"
      >
        <template v-slot:left>
          <router-link
            :to="{
              name: 'r_edit-resource',
              params: $route.params
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{
            displayLabelName(
              "resources",
              "resource-availability",
              "resource-availability"
            )
          }}
        </div>
      </top-header-menu-wrapper>
    </template>
    <ul class="clebex-item-section">
      <li class="clebex-item-section-item full-right-underline">
        <div class="clebex-item-content-wrapper">
          <span class="label clebex-item-label">{{
            displayLabelName(
              "resources",
              "resource-availability",
              "not-in-use-in-the-periods"
            )
          }}</span>
          <span
            class="follow-up-icons cursor-pointer"
            @click="
              $router.push({ name: 'r_edit-resource-add-unavailability' })
            "
            v-if="canItem(resource && resource.data, 'update')"
          >
            <span class="follow-up-icon-item">
              <icon icon="#cx-app1-add" width="12" height="12" />
            </span>
          </span>
        </div>
      </li>
      <template
        v-if="
          unavailabilities &&
            unavailabilities.data &&
            unavailabilities.data.length
        "
      >
        <template v-if="mode === 'selection'">
          <li
            v-for="unavailability in unavailabilities.data"
            :key="unavailability.id"
            class="clebex-item-section-item full-right-underline"
          >
            <div class="checkbox tiny alt block">
              <input
                type="checkbox"
                :id="`unav${unavailability.id}`"
                name="unavailability"
                :checked="selectedUnavailabilities.includes(unavailability.id)"
                @change="
                  selectedAvailabilities = [];
                  selectUnavailability(unavailability);
                "
              />
              <label :for="`unav${unavailability.id}`">
                <div class="clebex-item-content-wrapper">
                  <ul class="clebex-item-content-range">
                    <li class="clebex-item-range from">
                      <button class="clebex-item-range-cta">
                        {{ displayUnavailablityDate(unavailability.date_from) }}
                      </button>
                    </li>
                    <li class="clebex-item-range separator">
                      <icon
                        icon="#cx-app1-arrow-right-12x12"
                        width="12"
                        height="12"
                      />
                    </li>
                    <li class="clebex-item-range to">
                      <button class="clebex-item-range-cta">
                        {{ displayUnavailablityDate(unavailability.date_to) }}
                      </button>
                    </li>
                  </ul>
                </div>
              </label>
            </div>
          </li>
        </template>
        <li
          v-else
          v-for="unavailability in unavailabilities.data"
          :key="unavailability.id"
          @click="
            canItem(resource && resource.data, 'update')
              ? $router.push({
                  name: 'r_edit-resource-edit-unavailability',
                  params: {
                    resource_id: resource.data.id,
                    unavailability_id: unavailability.id
                  }
                })
              : null
          "
          class="clebex-item-section-item full-right-underline"
        >
          <div class="clebex-item-content-wrapper">
            <ul class="clebex-item-content-range">
              <li class="clebex-item-range from">
                <button class="clebex-item-range-cta">
                  {{ displayUnavailablityDate(unavailability.date_from) }}
                </button>
              </li>
              <li class="clebex-item-range separator">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                />
              </li>
              <li class="clebex-item-range to">
                <button class="clebex-item-range-cta">
                  {{ displayUnavailablityDate(unavailability.date_to) }}
                </button>
              </li>
            </ul>
          </div>
        </li>
      </template>
    </ul>
    <div
      style="width: 100%; color: #999999; padding: 10px; padding-right: 20px; text-align: right;"
      v-if="
        unavailabilities &&
          unavailabilities.data &&
          unavailabilities.data.length
      "
    >
      <span
        style="cursor: pointer;"
        @click="unavailabilitiesApplyAllModal = true"
        >{{
          displayLabelName(
            "resources",
            "messages",
            "unavailabilities-apply-to-all-button"
          )
        }}</span
      >
    </div>
    <ul class="clebex-item-section">
      <li class="clebex-item-section-item full-right-underline">
        <div class="clebex-item-content-wrapper">
          <span class="label clebex-item-label">{{
            displayLabelName(
              "resources",
              "resource-availability",
              "seasons-when-available-from"
            )
          }}</span>
          <span
            class="follow-up-icons cursor-pointer"
            @click="$router.push({ name: 'r_edit-resource-add-availability' })"
            v-if="canItem(resource && resource.data, 'update')"
          >
            <span class="follow-up-icon-item">
              <icon icon="#cx-app1-add" width="12" height="12" />
            </span>
          </span>
        </div>
      </li>
      <template
        v-if="
          availabilities && availabilities.data && availabilities.data.length
        "
      >
        <template v-if="mode === 'selection'">
          <li
            v-for="availability in availabilities.data"
            :key="availability.id"
            class="clebex-item-section-item full-right-underline"
          >
            <div class="checkbox tiny alt block">
              <input
                type="checkbox"
                :id="`avail${availability.id}`"
                name="availability"
                :checked="selectedAvailabilities.includes(availability.id)"
                @change="
                  selectedUnavailabilities = [];
                  selectAvailability(availability);
                "
              />
              <label :for="`avail${availability.id}`">
                <div class="clebex-item-range-wrapper">
                  <div class="clebex-item-content-wrapper">
                    <span class="label clebex-item-label">{{
                      availability.name
                    }}</span>
                  </div>
                  <div class="clebex-item-content-wrapper">
                    <ul class="clebex-item-content-range">
                      <li class="clebex-item-range from">
                        <button class="clebex-item-range-cta">
                          {{ displayAvailabilityDate(availability.date_from) }}
                        </button>
                      </li>
                      <li class="clebex-item-range separator">
                        <icon
                          icon="#cx-app1-arrow-right-12x12"
                          width="12"
                          height="12"
                        />
                      </li>
                      <li class="clebex-item-range to">
                        <button class="clebex-item-range-cta">
                          {{ displayAvailabilityDate(availability.date_to) }}
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div class="clebex-item-content-wrapper">
                    <div class="clebex-item-content-range hours-and-weekdays">
                      <div class="hours-range">
                        <span class="from">{{
                          displayTime(availability.time_from)
                        }}</span>
                        <span class="separator"> - </span>
                        <span class="to">{{
                          displayTime(availability.time_to)
                        }}</span>
                      </div>
                      <div class="weekdays">
                        (<span>{{
                          availability.mo
                            ? displayLabelName(
                                "resources",
                                "resource-availability",
                                "mo"
                              )
                            : "..."
                        }}</span>
                        <span>{{
                          availability.tu
                            ? displayLabelName(
                                "resources",
                                "resource-availability",
                                "tu"
                              )
                            : "..."
                        }}</span>
                        <span>{{
                          availability.we
                            ? displayLabelName(
                                "resources",
                                "resource-availability",
                                "we"
                              )
                            : "..."
                        }}</span>
                        <span>{{
                          availability.th
                            ? displayLabelName(
                                "resources",
                                "resource-availability",
                                "th"
                              )
                            : "..."
                        }}</span>
                        <span>{{
                          availability.fr
                            ? displayLabelName(
                                "resources",
                                "resource-availability",
                                "fr"
                              )
                            : "..."
                        }}</span>
                        <span>{{
                          availability.sa
                            ? displayLabelName(
                                "resources",
                                "resource-availability",
                                "sa"
                              )
                            : "..."
                        }}</span>
                        <span>{{
                          availability.su
                            ? displayLabelName(
                                "resources",
                                "resource-availability",
                                "su"
                              )
                            : "..."
                        }}</span
                        >)
                      </div>
                    </div>
                  </div>
                </div>
              </label>
            </div>
          </li>
        </template>
        <li
          v-else
          v-for="availability in availabilities.data"
          :key="availability.id"
          @click="
            canItem(resource && resource.data, 'update')
              ? $router.push({
                  name: 'r_edit-resource-edit-availability',
                  params: {
                    resource_id: resource.data.id,
                    availability_id: availability.id
                  }
                })
              : null
          "
          class="clebex-item-section-item full-right-underline"
        >
          <div class="clebex-item-content-wrapper">
            <span class="label clebex-item-label">{{ availability.name }}</span>
          </div>
          <div class="clebex-item-content-wrapper">
            <ul class="clebex-item-content-range">
              <li class="clebex-item-range from">
                <button class="clebex-item-range-cta">
                  {{ displayAvailabilityDate(availability.date_from) }}
                </button>
              </li>
              <li class="clebex-item-range separator">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                />
              </li>
              <li class="clebex-item-range to">
                <button class="clebex-item-range-cta">
                  {{ displayAvailabilityDate(availability.date_to) }}
                </button>
              </li>
            </ul>
          </div>
          <div class="clebex-item-content-wrapper">
            <div class="clebex-item-content-range hours-and-weekdays">
              <div class="hours-range">
                <span class="from">{{
                  displayTime(availability.time_from)
                }}</span>
                <span class="separator"> - </span>
                <span class="to">{{ displayTime(availability.time_to) }}</span>
              </div>

              <div class="weekdays">
                (<span>{{
                  availability.mo
                    ? displayLabelName(
                        "resources",
                        "resource-availability",
                        "mo"
                      )
                    : "..."
                }}</span>
                <span>{{
                  availability.tu
                    ? displayLabelName(
                        "resources",
                        "resource-availability",
                        "tu"
                      )
                    : "..."
                }}</span>
                <span>{{
                  availability.we
                    ? displayLabelName(
                        "resources",
                        "resource-availability",
                        "we"
                      )
                    : "..."
                }}</span>
                <span>{{
                  availability.th
                    ? displayLabelName(
                        "resources",
                        "resource-availability",
                        "th"
                      )
                    : "..."
                }}</span>
                <span>{{
                  availability.fr
                    ? displayLabelName(
                        "resources",
                        "resource-availability",
                        "fr"
                      )
                    : "..."
                }}</span>
                <span>{{
                  availability.sa
                    ? displayLabelName(
                        "resources",
                        "resource-availability",
                        "sa"
                      )
                    : "..."
                }}</span>
                <span>{{
                  availability.su
                    ? displayLabelName(
                        "resources",
                        "resource-availability",
                        "su"
                      )
                    : "..."
                }}</span
                >)
              </div>
            </div>
          </div>
        </li>
      </template>
    </ul>
    <div
      style="width: 100%; color: #999999; padding: 10px; padding-right: 20px; text-align: right;"
      v-if="availabilities && availabilities.data && availabilities.data.length"
    >
      <span
        style="cursor: pointer;"
        @click="availabilitiesApplyAllModal = true"
        >{{
          displayLabelName(
            "resources",
            "messages",
            "availabilities-apply-to-all-button"
          )
        }}</span
      >
    </div>
    <template v-slot:footer>
      <nav class="actions-menu theme-gray">
        <ul class="actions-list">
          <li class="action">
            <button
              class="action-btn"
              @click="setMode(mode === 'selection' ? null : 'selection')"
              :class="{ active: mode === 'selection' }"
            >
              {{
                displayLabelName("resources", "resource-availability", "select")
              }}
            </button>
          </li>
          <li class="action" :style="deleteButtonStyle">
            <button class="action-btn" @click="show = true">
              <svg-icon icon="garbage"></svg-icon>
            </button>
            <span class="action-btn deleted-count">{{
              "(" + selectedValuesCount + "/" + allValuesCount + ")"
            }}</span>
          </li>
        </ul>
      </nav>
    </template>
    <screen-modal
      v-if="show"
      class="confirm-modal"
      type="success"
      :confirm-action="startDelete"
      :confirm-button-label="displayLabelName('global.buttons.ok')"
      :cancelButtonLabel="displayLabelName('global.buttons.cancel')"
      :show="show"
      @close="show = false"
    >
      <h3 class="modal-title">
        {{
          displayLabelName(
            "resources",
            "resource-availability",
            this.selectedAvailabilities.length
              ? "delete-title"
              : "delete-title-secondary"
          )
        }}
      </h3>
      <p class="text">
        {{
          displayLabelName(
            "resources",
            "resource-availability",
            this.selectedAvailabilities.length
              ? "delete-action-text"
              : "delete-action-text-secondary"
          )
        }}
        <br />
        {{ displayLabelName("global.messages.delete-action-question") }}
      </p>
    </screen-modal>
    <screen-modal
      v-if="unavailabilitiesApplyAllModal"
      class="confirm-modal"
      type="success"
      :confirm-action="unavailabilitiesApplyToAll"
      :confirm-button-label="displayLabelName('global.buttons.ok')"
      :cancelButtonLabel="displayLabelName('global.buttons.cancel')"
      :show="unavailabilitiesApplyAllModal"
      @close="unavailabilitiesApplyAllModal = false"
    >
      <h3 class="modal-title">
        {{
          displayLabelName(
            "resources",
            "messages",
            "unavailabilities-apply-to-all-title"
          )
        }}
      </h3>
      <p class="text">
        {{
          displayLabelName(
            "resources",
            "messages",
            "unavailabilities-apply-to-all-text"
          )
        }}
        <br />
        {{ displayLabelName("global.messages.delete-action-question") }}
      </p>
    </screen-modal>
    <screen-modal
      v-if="availabilitiesApplyAllModal"
      class="confirm-modal"
      type="success"
      :confirm-action="availabilitiesApplyToAll"
      :confirm-button-label="displayLabelName('global.buttons.ok')"
      :cancelButtonLabel="displayLabelName('global.buttons.cancel')"
      :show="availabilitiesApplyAllModal"
      @close="availabilitiesApplyAllModal = false"
    >
      <h3 class="modal-title">
        {{
          displayLabelName(
            "resources",
            "messages",
            "availabilities-apply-to-all-title"
          )
        }}
      </h3>
      <p class="text">
        {{
          displayLabelName(
            "resources",
            "messages",
            "availabilities-apply-to-all-text"
          )
        }}
        <br />
        {{ displayLabelName("global.messages.delete-action-question") }}
      </p>
    </screen-modal>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapState, mapActions, mapGetters } from "vuex";
import { formatDate } from "@/services/helpers";
import { apiEndpoints } from "@/services/constants";
import httpServiceAuth from "@/services/http-service";
import { errorHandler } from "@/services/error-handler";
import dayjs from "dayjs";
import helpOnlineMixin from "@/services/mixins/help_online/help-online-mixin";

export default {
  name: "ResourceAvailability",
  mixins: [helpOnlineMixin],
  data() {
    return {
      mode: null,
      show: false,
      availabilitiesApplyAllModal: false,
      unavailabilitiesApplyAllModal: false,
      selectedItem: null,
      helpSlug: "resources-availability",
      selectedAvailabilities: [],
      selectedUnavailabilities: []
    };
  },
  computed: {
    ...mapState("resource", ["resource"]),
    ...mapState("resourceAvailability", ["availabilities", "unavailabilities"]),
    ...mapGetters("settings", ["globalDateFormat", "globalTimeFormat"]),
    deleteButtonStyle() {
      if (
        this.mode === "selection" &&
        (this.selectedAvailabilities.length ||
          this.selectedUnavailabilities.length)
      ) {
        return {
          visibility: "visible"
        };
      }

      return {
        visibility: "hidden"
      };
    },
    allValuesCount() {
      let response = 0;

      if (
        this.selectedUnavailabilities.length &&
        this.unavailabilities &&
        this.unavailabilities.data &&
        this.unavailabilities.data.length
      ) {
        response = this.unavailabilities.data.length;
      } else if (
        this.selectedAvailabilities.length &&
        this.availabilities &&
        this.availabilities.data &&
        this.availabilities.data.length
      ) {
        response = this.availabilities.data.length;
      }

      return response;
    },
    selectedValuesCount() {
      let response = 0;

      if (
        this.selectedUnavailabilities &&
        this.selectedUnavailabilities.length
      ) {
        response = this.selectedUnavailabilities.length;
      } else if (
        this.selectedAvailabilities &&
        this.selectedAvailabilities.length
      ) {
        response = this.selectedAvailabilities.length;
      }

      return response;
    }
  },
  created() {
    const { resource } = this;
    if (resource && resource.data && resource.data.id) {
      this.getAvailabilities(resource.data.id);
      this.getUnavailabilities(resource.data.id);
    }
  },
  methods: {
    ...mapActions("resourceAvailability", [
      "getAvailabilities",
      "getUnavailabilities"
    ]),
    displayAvailabilityDate(date) {
      const splitDate = date.split("-");
      return formatDate(
        {
          day: splitDate[1],
          month: splitDate[0],
          year: dayjs().format("YYYY")
        },
        "D MMMM",
        true
      );
    },
    displayUnavailablityDate(date) {
      const splitDate = date.split("-");
      return formatDate(
        {
          day: splitDate[2],
          month: splitDate[1],
          year: splitDate[0]
        },
        this.globalDateFormat || "DD MMMM YYYY"
      );
    },
    setMode(mode) {
      this.selectedItem = null;
      this.mode = mode;
    },
    async startDelete() {
      this.processing = true;
      let itemType = this.selectedUnavailabilities.length
        ? "resourceunavailabilities"
        : "resourceavailabilities";

      if (itemType == "resourceunavailabilities") {
        for (var i = 0; i < this.selectedUnavailabilities.length; i++) {
          await httpServiceAuth.delete(
            `${apiEndpoints.company.resources}/${this.resource.data.id}/${itemType}/${this.selectedUnavailabilities[i]}`
          );
        }

        this.selectedItem = null;
        this.setMode(null);
        this.processing = false;
        this.selectedUnavailabilities = [];
        this.getUnavailabilities(this.resource.data.id);
      } else if (itemType == "resourceavailabilities") {
        for (var j = 0; j < this.selectedAvailabilities.length; j++) {
          await httpServiceAuth.delete(
            `${apiEndpoints.company.resources}/${this.resource.data.id}/${itemType}/${this.selectedAvailabilities[j]}`
          );
        }

        this.selectedItem = null;
        this.setMode(null);
        this.processing = false;
        this.selectedAvailabilities = [];
        this.getAvailabilities(this.resource.data.id);
      }
    },
    displayTime(time) {
      const parsedTime = dayjs(
        time,
        this.globalTimeFormat.replace(" A", "")
      ).toDate();
      return formatDate(parsedTime.toString(), this.globalTimeFormat);
    },
    unavailabilitiesApplyToAll() {
      this.$store.commit("loader/setScreenLoading", true, {
        root: true
      });
      httpServiceAuth
        .get(
          `${apiEndpoints.company.resources}/${this.resource.data.id}/resourceunavailabilities/allresources`
        )
        .catch(error => {
          if (error.response) {
            errorHandler(error.response, this.findElement());
          }
        })
        .finally(() => {
          this.selectedItem = null;
          this.setMode(null);
          this.$store.commit("loader/setScreenLoading", false, {
            root: true
          });
        });
    },
    selectUnavailability(unavailability) {
      if (this.selectedUnavailabilities.includes(unavailability.id)) {
        this.selectedUnavailabilities.splice(
          this.selectedUnavailabilities.indexOf(unavailability.id),
          1
        );
      } else {
        this.selectedUnavailabilities.push(unavailability.id);
      }
    },
    selectAvailability(availability) {
      if (this.selectedAvailabilities.includes(availability.id)) {
        this.selectedAvailabilities.splice(
          this.selectedAvailabilities.indexOf(availability.id),
          1
        );
      } else {
        this.selectedAvailabilities.push(availability.id);
      }
    },
    availabilitiesApplyToAll() {
      this.$store.commit("loader/setScreenLoading", true, {
        root: true
      });
      httpServiceAuth
        .get(
          `${apiEndpoints.company.resources}/${this.resource.data.id}/resourceavailabilities/allresources`
        )
        .catch(error => {
          if (error.response) {
            errorHandler(error.response, this.findElement());
          }
        })
        .finally(() => {
          this.selectedItem = null;
          this.setMode(null);
          this.$store.commit("loader/setScreenLoading", false, {
            root: true
          });
        });
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  }
};
</script>
<style>
.deleted-count {
  position: relative;
  top: -4px;
  margin: 5px 8px;
  padding-left: 0px !important;
  margin-left: 0px;
}
</style>
